<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "preview",
    created(){
        document.body.style.backgroundColor = 'white';
    }
}
</script>

<style scoped>

</style>